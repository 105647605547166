import React from "react"
import VideoPage from "../../../components/VideoPage"

const VideoLive = props => {
  console.log(props)
  return (
    <VideoPage videoId={props.params.videoId} static={false} search={props.search} location={props.location} />
  )
}

export default VideoLive